/***** Common CSS for all versions *****/
.element-wrapper {
  margin-bottom: 20px;
}
.element-title {
  margin-left: 15px;
  margin-bottom: 10px;
  color: #4f1b75;
}
.ddm-elements {
  margin: 0 25px 0;
}
.ddm-elements .MuiFormControlLabel-label {
  color: #4f1b75;
}
.ddm-elements .MuiCheckbox-root {
  padding: 5px;
}

/***** Version wise CSS specification *****/
.version_2_5_0_container
  .transfer-formats-elements-container
  .MuiFormGroup-root,
.version_2_5_0_container .max-tex-elements-container .MuiFormGroup-root {
  flex-direction: row;
}

.version_2_5_6_container
  .transfer-formats-elements-container
  .MuiFormGroup-root,
.version_2_5_6_container .max-tex-elements-container .MuiFormGroup-root,
.version_2_5_6_container .garment-elements-container .MuiFormGroup-root,
.version_2_5_6_container .gender-elements-container .MuiFormGroup-root,
.version_2_5_6_container
  .avatar-structure-elements-container
  .MuiFormGroup-root {
  flex-direction: row;
}

.version_2_5_7_container
  .transfer-formats-elements-container
  .MuiFormGroup-root,
.version_2_5_7_container .max-tex-elements-container .MuiFormGroup-root,
.version_2_5_7_container .garment-elements-container .MuiFormGroup-root,
.version_2_5_7_container .gender-elements-container .MuiFormGroup-root,
.version_2_5_7_container
  .avatar-structure-elements-container
  .MuiFormGroup-root {
  flex-direction: row;
}

.version_2_5_9_container
  .transfer-formats-elements-container
  .MuiFormGroup-root,
.version_2_5_9_container .max-tex-elements-container .MuiFormGroup-root,
.version_2_5_9_container .garment-elements-container .MuiFormGroup-root,
.version_2_5_9_container .gender-elements-container .MuiFormGroup-root,
.version_2_5_9_container
  .avatar-structure-elements-container
  .MuiFormGroup-root,
.version_2_5_9_container .hair-elements-container .MuiFormGroup-root {
  flex-direction: row;
}

.version_2_5_10_container
  .transfer-formats-elements-container
  .MuiFormGroup-root,
.version_2_5_10_container
  .avatar-structure-elements-container
  .MuiFormGroup-root,
.version_2_5_10_container .hair-elements-container .MuiFormGroup-root {
  flex-direction: row;
}

.version_2_5_10_container .body-pose-profile-element-container {
  display: flex;
}
.version_2_5_10_container .body-pose-profile-element-container .c-width {
  width: 50%;
}
.version_2_5_10_container .body-pose-profile-element-container .m-right {
  width: 50%;
  border-right: 2px solid #dadada;
  margin-right: 15px;
}
