#configure-api-key-title {
  font-size: 16px;
  padding-left: 10px;
}

#notificationData {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding-top: 15px;
}
