@media only screen and (max-width: 767px) {
  .BannerText {
    font-size: 20px;
    color: #5bed96;

    font-weight: bold;
  }

  .firstText {
    width: 150px;
    color: #5bed96;
    font-weight: bold;
  }

  .BannerText2 {
    color: #5bed96;

    font-size: 11px;
  }

  .MainHeading {
    width: 80px;
  }

  .text1 {
    font-size: 11px !important;
  }
}

@media only screen and (min-width: 769px) {
  .firstText {
    width: 208px;
  }

  .MainHeading {
    width: 208px;
  }

  .bannerWrapper {
    height: 124px;
  }

  .BannerText {
    width: 505px;

    color: #5bed96;
    padding-top: 3px;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 0.9em;
  }

  .BannerText2 {
    color: #5bed96;
    padding-top: 10px;
    font-size: 18px;

    margin-left: -161px;
  }
}
