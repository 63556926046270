@media (min-width: 900px) {
  .css-1o6ki99 {
    padding-left: 236px;
  }
}

.css-r2jef8 {
  margin: 0px;
  border-radius: 5px;
  background-color: #eeeeee;
}

.container_main {
  background-color: #ffffff;
  max-width: 100%;
  padding: 25px;
}

.check_bar {
  padding: 0 15px;
}

@media (max-width: 576px) {
  .welcome_content {
    padding: 0 15px;
  }

  .container_main .css-vj1n65-MuiGrid-root {
    padding: 0 !important;
  }

  .container_main .main_title {
    font-size: 48px !important;
  }

  .container_main .sub_title {
    font-size: 28px !important;
  }

  .container_main .content {
    margin-top: 10px;
    padding: 0 !important;
  }

  .container_main .list_details {
    padding-left: 45px !important;
  }

  .container_main .check_bar {
    text-align: left !important;
  }
}

.SpinnerContainer .MuiCircularProgress-root {
  width: 26px !important;
  height: 26px !important;
  color: #2a0651 !important;
}

.SpinnerContainer .signIn-btn,
.confirm-pwd-btn {
  background-color: #5ced96;
  color: #2a0651;
  text-transform: capitalize;
}
.confirm-pwd-btn:active {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.confirm-pwd-btn:hover {
  background-color: #5ced96;
}
.SpinnerContainer .Create-account-btn {
  background-color: #5ced96;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
}

.Login-container .main-header {
  color: #2a0651;
}

.Login-container .css-1o2jng6-MuiOutlinedInput-notchedOutline {
  border: 1px solid #2a0651;
  color: #2a0651;
}

.Login-container .iconStyleBtn {
  background-color: transparent;
  color: black;
  border: 1px solid grey;
}

/*sign up container css*/
.signup-container .main-header {
  color: #2a0651;
  text-align: center;
}

.signup-container .css-1o2jng6-MuiOutlinedInput-notchedOutline {
  border: 1px solid #2a0651;
  color: #2a0651;
}

.signup-container .iconStyleBtn {
  background-color: transparent;
  color: black;
  border: 1px solid grey;
}
.resend-click-link {
  cursor: pointer;
  text-decoration: none;
  color: blue;
}
