.createOrderContainer .MuiTypography-h5 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 20px;
}
.user_detail {
  padding: 0;
  margin-top: 20px;
}
.user_detail .user_detail .MuiFormControl-root {
  margin: 0 !important;
}
.createOrderFooter {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.createOrderFooter .MuiCircularProgress-root {
  height: 26px !important;
  width: 26px !important;
  color: #ffffff !important;
}
