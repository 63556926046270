h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.m0 {
  margin: 0 !important;
}
.mr-3 {
  margin-right: 50px;
}
.didimo-card {
  height: 280px;
  position: relative;
}
.didimo-card .MuiDialogContent-root {
  padding: 0 24px !important;
}
.detail-popup {
  font-size: 16px !important;
}

.detail-popup .MuiFormControlLabel-label {
  font-size: 13px !important;
}
.input-detail .MuiFormControlLabel-root {
  margin-left: -4px !important;
}
.input-detail .MuiCheckbox-root {
  padding: 4px !important;
}
.profile-detail {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid darkblue;
}
.profile-detail .mb-3 {
  margin-bottom: 30px;
}
.profile-detail h4 {
  margin: 0;
  /*margin-bottom: 10px;*/
}
.profile-detail .character-text {
  display: flex;
  justify-content: space-between;
}
.profile-detail .character-icon {
  color: gray;
  width: 24px;
  height: 24px;
}
.profile-detail .input-detail {
  margin-bottom: 20px;
}

.profile-detail .input-detail .coming-text {
  text-align: center;
  color: #00000061;
}
.profile-detail .line hr {
  width: 50%;
  height: 1px;
  margin: 20px auto;
  background: gray;
}
.profile-detail .info-detail-1 {
  margin-bottom: 20px;
}
.profile-detail .info-detail-1 {
  display: grid;
  grid-template-columns: 4fr 5fr;
}
.profile-detail .info-detail-1 .info-label {
  grid-column: 1;
}
.profile-detail .info-detail-1 .info-value {
  grid-column: 2;
  text-align: right;
}
.profile-detail .info-detail-1 .info-header {
  margin-top: 1em;
  grid-column: 1 / span 2;
}

.more-option {
  width: 100px;
}

@media (max-width: 992px) {
  .detail-popup .MuiDialog-paperFullScreen {
    width: 60% !important;
  }
}
@media (max-width: 768px) {
  .detail-popup .MuiDialog-paperFullScreen {
    width: 60% !important;
  }
}
@media (max-width: 576px) {
  .detail-popup .MuiDialog-paperFullScreen {
    width: 100% !important;
  }
}
@media (max-width: 375px) {
  .detail-popup .MuiDialogContent-root {
    padding: 0 !important;
  }
  .detail-popup .MuiFormControlLabel-root {
    margin-right: 14px !important;
  }
}
.MuiTouchRipple-root {
  display: none;
}
.MuiListItem-button:hover {
  background-color: unset !important;
}

.profile-detail .input-detail .MuiFormControlLabel-root,
.profile-detail .input-detail .MuiIconButton-label {
  cursor: default;
}

/*//////////////////*/

.my_didimos {
  margin: 20px 20px 0 20px;
}
.my_didimos .MuiAlert-outlinedWarning {
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  font-size: 12px;
  align-items: center;
}
.my_didimos .MuiTypography-h5 {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.edit_group .MuiButton-outlined {
  margin-left: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-transform: capitalize;
}
.mos_card {
  grid-template-columns: repeat(auto-fill, minmax(12%, 1fr));
  flex-wrap: wrap;
}
.mos_card > div {
  width: 100%;
  height: 100%;
}
.card_content {
  position: relative;
}
.didimo_key {
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card_content .select_check,
.card_content .filter_ckeck {
  position: absolute;
  top: 0;
  padding: 0;
}
.card_content .select_check {
  left: 0;
}
.card_content .filter_ckeck {
  right: 0;
}
.card_content .css-eeaqny-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0;
}
.d-grid {
  display: grid;
}
.card_img {
  height: 225px;
  background-color: white;
}
.card_body {
  margin-top: 10px;
  padding: 8px;
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.card_body p {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
}
.menu_btn {
  position: absolute;
  right: 0;
}
.mos_card .MuiGrid-item {
  padding: 12px;
}
/*.MuiMenuItem-gutters:hover {*/
/*  background-color: unset !important;*/
/*}*/
#simple-menu .MuiButtonBase-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.filter_bar {
  margin: 0 15px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
}
.filter_bar .Show_demos .MuiFormControlLabel-label {
  margin: 18px 0;
  font-weight: 500;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.filter_bar .filterbtn {
  margin-left: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 18px;
  text-transform: inherit;
  color: black;
}

/* Edit Modal*/
.edit_modal .css-1s305u7-MuiPaper-root-MuiDialog-paper {
  padding: 16px;
}
.edit_modal .css-ypiqx9-MuiDialogContent-root {
  padding-top: 5px;
}
.font_fam {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.edit_modal .MuiOutlinedInput-input {
  padding: 16px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.edit_modal .css-b2xtjn-MuiTypography-root-MuiDialogTitle-root {
  font-size: 16px;
}
.edit_modal .MuiInputLabel-outlined {
  color: #263238;
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  top: 2px;
}
.edit_modal .MuiButton-textSizeMedium {
  text-transform: inherit;
}

/* Delete Modal*/
.delete_modal .css-mnetzy-MuiTypography-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
}

.my_didimos .MuiGrid-item {
  position: static !important;
  min-width: 12%;
}

.filter_ckeck {
  height: 25px;
  width: 25px;
}

/*.profile-detail .input-detail:nth-child(-n + 2) .elementContainer,
.profile-detail .input-detail:nth-child(4) .elementContainer,
.profile-detail .input-detail:nth-child(5) .elementContainer {
  display: flex;
}
.profile-detail .input-detail:nth-child(6) .elementContainer {
  display: flex;
  justify-content: space-between;
}*/
.profile-detail .input-detail:nth-child(1) .elementContainer .MuiFormGroup-root,
.profile-detail .input-detail:nth-child(2) .elementContainer .MuiFormGroup-root,
.profile-detail .input-detail:nth-child(4) .elementContainer .MuiFormGroup-root,
.profile-detail
  .input-detail:nth-child(5)
  .elementContainer
  .MuiFormGroup-root {
  width: 30%;
}

/*.input-detail:last-child {*/
/*  display: block;*/
/*}*/
.input-detail .didimo-code {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.input-detail .didimo-code .MuiFormGroup-root {
  flex-direction: row;
}
.input-detail:nth-child(1) .didimo-code {
  display: flex;
}
.ml-2 {
  margin-left: 10px;
}
/*.input-detail:nth-child(4) .didimo-code,
.input-detail:nth-child(6) .didimo-code {
  display: block;
}*/

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 600px;
}

.loading {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.didimos-details-nodata {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 2400px) {
  .mos_card {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
  .my_didimos .MuiGrid-item {
    min-width: 20%;
  }
}
@media (max-width: 1440px) {
  .mos_card {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    flex-wrap: wrap;
  }
  .my_didimos .MuiGrid-item {
    min-width: 220px;
  }
}
@media (max-width: 1024px) {
  .mos_card {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    flex-wrap: wrap;
  }
  .my_didimos .MuiGrid-item {
    min-width: 320px;
  }
}
@media (max-width: 768px) {
  .mos_card {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    flex-wrap: wrap;
  }
  .my_didimos .MuiGrid-item {
    min-width: 240px;
  }
}

@media (max-width: 532px) {
  .mos_card {
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .css-13i4rnv-MuiGrid-root {
    max-width: 16.66%;
  }
}

@media (max-width: 532px) {
  .mos_card {
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .css-13i4rnv-MuiGrid-root {
    max-width: unset;
  }
}

.myDidimoContainer .css-185gdzj-MuiCardHeader-root {
  display: block !important;
}
.myDidimoContainer .css-1nyfb21-MuiButtonBase-root-MuiButton-root {
  width: 109px;
}
.myDidimoContainer .header_content {
  padding: 8px 15px;
}
@media (max-width: 425px) {
  .myDidimoContainer .css-1nyfb21-MuiButtonBase-root-MuiButton-root {
    width: auto;
  }
}
.myDidimoContainer .show_demos_title {
  margin-left: 15px;
}
.myDidimoContainer .button_container {
  min-width: 220px;
}
.myDidimoContainer .css-185gdzj-MuiCardHeader-root {
  padding: 16px 16px 16px 0;
}
.myDidimoContainer .didimos-header {
  display: flex !important;
  padding: 20px 20px;
}
.myDidimoContainer .css-bhp9pd-MuiPaper-root-MuiCard-root {
  margin: 0;
}

@media (max-width: 575px) {
  .css-1tj750t {
    padding: 10px !important;
  }
}
@media (max-width: 425px) {
  .css-1tj750t .css-13i4rnv-MuiGrid-root {
    max-width: 100%;
  }
}

.capturePhoto .css-1hju3x6-MuiPaper-root-MuiDialog-paper{
  margin: 32px 15px;
}

@media (max-width: 575px) {
  .webCame_image{
    height: 294px!important;
    width: 200% !important;
    margin-left: -51% !important;
    object-fit: contain;
  }
  .webcame_video{
    object-fit: cover;
  }
}
