.div-root-11 {
  position: relative;
}
.view_didimo_model .MuiBackdrop-root .MuiCircularProgress-root {
  color: white;
}
.view_didimo_model .css-sox5kk-MuiBackdrop-root {
  height: calc(100vh - 145px);
  position: relative;
}
