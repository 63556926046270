.didimo-detail-container .MuiCard-root {
  /*padding: 0 40px 40px;*/
}
.didimo-detail-container .details_body {
  width: 80%;
}
.didimo-detail-container .details_container {
  padding: 0;
  width: 100%;
}

.download_btn_group {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.didimo-detail-container
  .information-content
  .all_details
  .download_btn_group
  button {
  background: #5bed96;
  margin-bottom: 10px;
}
.didimo-detail-container
  .information-content
  .all_details
  .download_btn_group
  .Mui-disabled {
  background: #caead5;
}

.didimo-detail-container .character-text {
  width: 90%;
  margin: auto;
}
.didimo-detail-container .character-text h4 {
  margin-bottom: 10px !important;
}
.didimo-detail-container .elementDetail {
  width: 75%;
  margin: 0px 25px 0px;
}
.details_container .input-detail:nth-child(2) .elementDetail,
.details_container .input-detail:nth-child(4) .elementDetail {
  display: flex;
  justify-content: space-between;
}
.details_container .input-detail .elementDetail .MuiFormControlLabel-label {
  color: #4f1b75;
}
.details_container .input-detail {
  margin-bottom: 20px;
}

.didimo-detail-container .line {
  width: 50%;
  margin: 20px auto;
}
.didimo-detail-container .Meta-detail-1 {
  /*display: grid;*/
  width: 90%;
  margin: auto auto 10px;
  /*grid-template-columns: 4fr 5fr;*/
}
.didimo-detail-container .info-text {
  text-align: left;
  font-weight: 600;
  width: 50%;
}
.didimo-detail-container .info-text,
.didimo-detail-container .info-value {
  margin-bottom: 20px;
}
.didimo-detail-container .info-value {
  text-align: left;
  word-break: break-all;
  width: 45%;
}

.didimo-detail-container .butttonContainer button {
  text-transform: capitalize;
  margin-left: 5px;
}

.didimo-detail-container .MuiCardHeader-root span {
  /*text-align: center;*/
}
.meta-header {
  padding: 16px;
}
.meta-header h2 {
  padding-bottom: 10px;
  color: #4f1b75;
}

.ddmoData-container {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.Meta_detail_items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.custom-configuration {
  width: 90%;
  margin: auto;
}
.custom_config_items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: black;
}
.custom_config_items .name {
  width: 50%;
  margin-bottom: 20px;
}
.custom_config_items .value {
  width: 45%;
  margin-bottom: 20px;
  text-align: left;
}
.custom_config_header {
  margin-bottom: 10px;
  font-weight: 500;
  color: black;
}

@media (max-width: 1200px) {
  .didimo-detail-container .details_body {
    width: 100%;
  }
}

.ddmoData-container .didimo_img_details {
  border-right: 2px solid #dadada;
}
@media (max-width: 992px) {
  .didimo-detail-container .details_body {
    width: 100%;
  }
  .ddmoData-container {
    flex-wrap: wrap;
  }
  .didimo-detail-container .information-content .input-container {
    border-left: transparent;
  }
  .didimo-detail-container .information-content .input-container,
  .didimo-detail-container .information-content .all_details {
    width: 50%;
  }
  .download_btn_group button:last-child {
    margin: 0 5px;
  }
  .didimo-detail-container .details_container {
    padding: 0;
  }
  .didimo-detail-container .MuiCard-root {
    padding: 20px;
  }
  .ddmoData-container .didimo_img_details {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .ddmoData-container .didimo_img_details {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .information-content {
    flex-wrap: wrap;
  }
  .didimo-detail-container .information-content .input-container,
  .didimo-detail-container .information-content .all_details {
    width: 100%;
  }
  .didimo-detail-container .information-content .input-container {
    border: none;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .ddmoData-container .didimo_img_details {
    width: 100% !important;
    display: block;
  }
  .meta-header h2 {
    word-break: break-all;
  }
}
.divider {
  border: none;
  height: 1px;
  background: #e7e7e7;
}
@media (max-width: 500px) {
  .didimo-detail-container .MuiCard-root {
    padding: 20px 0;
  }
  .didimo-detail-container .info-text {
    width: 48%;
  }
  .didimo-detail-container .info-value {
    width: auto;
    font-size: 12px;
  }
  .didimo-detail-container .Meta_detail_items {
    flex-wrap: nowrap;
    justify-content: unset;
  }
  .didimo-detail-container .ddmoData-container {
    padding: 10px 0;
  }
  .didimo-detail-container .elementDetail {
    /*width: 90%;*/
  }
  .details_container .input-detail .elementDetail .MuiFormControlLabel-label {
    font-size: 13px;
  }
  .details_container .input-detail:nth-child(2) .elementDetail,
  .details_container .input-detail:nth-child(4) .elementDetail {
    /*justify-content:unset;*/
  }
}
@media (max-width: 375px) {
  .didimo-detail-container .elementDetail {
    width: 85%;
  }
}

.css-12nbfgv-MuiPaper-root-MuiDrawer-paper {
  position: unset;
}
.css-12nbfgv-MuiPaper-root-MuiDrawer-paper .css-4nuj1i {
  padding-top: 90px;
}
