.myDidimoContainer .filter-alert .MuiAlert-standardError {
  margin: 0;
  background-color: #fdeded;
  border-radius: 5px;
  border-color: #eba6a6;
  font-size: 13px;
}
.myDidimoContainer .MuiButton-root {
  text-transform: capitalize;
}

/*.myDidimoContainer .MuiAlert-root {
  color: rgb(102, 60, 0);
  border: 1px solid #ff9800;
  margin-bottom: 10px;
  background-color: #fff;
}*/
.myDidimoContainer .didimo_content {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  display: flex;
  flex-wrap: wrap;
}
.myDidimoContainer .data_content {
  position: relative !important;
}

.DemoConatainer {
  margin: 0 15px 8px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(236, 236, 236);
}

/*DidimoData*/

.mos_card {
  padding-bottom: 10px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  display: grid;
}

.d-grid {
  display: grid;
}

.card_img {
  min-height: 225px;
  min-width: 225px;
  height: 225px;
  cursor: pointer;
}

.card_body {
  margin-top: 10px;
  padding: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.card_body p {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  margin: 0px 41px 0 20px;
  font-size: 15px;
}

.card_bottom {
  margin-top: 10px;
  padding: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.menu_btn {
  position: absolute;
  right: 0;
  margin: -8px -26px 0 0px;
}

.people_icon {
  position: absolute;
  left: 10px;
}

.mos_card .MuiGrid-item {
  padding: 12px;
}

/*.MuiMenuItem-gutters:hover {*/
/*  background-color: unset !important;*/
/*}*/

#simple-menu .MuiButtonBase-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

/*didimoCheckBox's*/
.card_content {
  position: relative;
  text-align: center;
}

.card_content .select_check,
.card_content .filter_ckeck {
  position: absolute;
  top: 0;
  padding: 0;
}

.card_content .select_check {
  left: 0;
}

.card_content .filter_ckeck {
  right: 0;
}

@media (max-width: 576px) {
  .HideChip {
    display: none;
  }
}

/* Edit Modal*/
.edit_modal .css-1s305u7-MuiPaper-root-MuiDialog-paper {
  padding: 16px;
}
.edit_modal .css-ypiqx9-MuiDialogContent-root {
  padding-top: 5px;
}
.font_fam {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.edit_modal .MuiOutlinedInput-input {
  padding: 16px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.edit_modal .css-b2xtjn-MuiTypography-root-MuiDialogTitle-root {
  font-size: 16px;
}
.edit_modal .MuiInputLabel-outlined {
  color: #263238;
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  top: 2px;
}
.edit_modal .MuiButton-textSizeMedium {
  text-transform: inherit;
}

/* Delete Modal*/
.delete_modal .css-mnetzy-MuiTypography-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
}

ul.pagination {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 15px;
}
ul.pagination li a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  cursor: pointer;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
ul.pagination li {
  display: inline-block;
  margin: 0 5px 0 0px;
}

ul.pagination li a:hover {
  background: #2d265126;
}
ul.pagination li a:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 75%);
  z-index: 2;
}

ul.pagination li.active a {
  background: #2d2651;
  color: #fff;
}

.css-6hp17o-MuiList-root-MuiMenu-list
  li:nth-child(n + 4)
  .makeStyles-moreDetails-13 {
  border-bottom: none;
}
.css-6hp17o-MuiList-root-MuiMenu-list li .makeStyles-moreDetails-13 {
  padding: 5px 10px;
  font-size: 14px;
}

.myDidimoContainer .css-cpgvjg-MuiSnackbar-root {
  bottom: 24px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}
ul.pagination li a:hover {
  background: #2f08f926;
}
ul.pagination li a.active {
  background-color: #2d2651;
}
.didimos-pagination {
  display: flex;
  justify-content: center;
  padding: 25px;
}

/** 2022.07.26 **/
.progress-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 40px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 10px;
  border: 1px solid rgb(236, 236, 236);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  max-height: 120px;
  overflow: auto;
}
.progress-body .progress-item {
  padding: 5px;
}
.progress-bar-body {
  position: relative;
  margin-top: 5px;
  width: 100%;
  height: 6px;
  background-color: #0002;
}
.progressing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #2a0651;
}
.progressingSuccess {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: green;
}

.progress-title-container {
  display: flex;
  flex-direction: column;
}

.progress-title-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.txt-ellipse1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: left;
  white-space: normal;
  -webkit-line-clamp: 1;
}

.txt-progress-title {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.txt-progress-description {
  font-size: 12px;
  font-weight: 300;
  color: #000;
}

@media screen and (max-width: 768px) {
  .progress-body {
    grid-template-columns: 1fr !important;
    grid-column-gap: 0 !important;
  }
  .progress-body > div:nth-child(2) {
    display: none !important;
  }
  .progress-body > div:nth-child(3) {
    display: none !important;
  }
}

.generate-info-icon {
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}
