.new_modal .line {
  display: flex;
  margin-bottom: 20px;
}

.new_modal .line h4 {
  margin-right: 20px;
}

.checkbox_fav {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox_fav .MuiCheckbox-root {
  padding-left: 0;
}

.checkbox_fav h4 {
  margin-bottom: 0;
}

.date {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.date .date_1 {
  width: 100%;
  padding-right: 10px;
}

.date .date_1 .react-datepicker-wrapper input {
  padding: 14px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  width: 100%;
}
.date .date_1 .react-datepicker-popper {
  z-index: 2;
}

.date .date_1 h4 {
  margin-top: 10px;
  margin-right: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.date .date_2 {
  display: flex;
  padding-left: 10px;
}

.date .date_2 h4 {
  margin-right: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.form_drop_down {
  width: 100%;
}

.Sort_drop {
  margin-top: 16px;
}

@media (max-width: 576px) {
  .date .date_1 {
    width: 100%;
    border-right: 0;
    padding: 0;
  }

  .date .date_1 h4 {
    margin-right: 0px;
  }

  .date .date_2 {
    display: block;
    padding-left: 0px;
  }

  .date_1,
  .date_2 {
    width: 100%;
  }

  .date_1 .MuiTextField-root,
  .date_2 .MuiTextField-root {
    width: 100%;
  }

  /*.date{*/
  /*    display: none;*/
  /*}*/
  .date_mobil {
    display: block;
  }
}

.spacing {
  margin-bottom: 20px !important;
}

.dateFilter {
  display: flex;
}

.dateFilter-fromLabel {
  margin-left: 20px !important;
}

.form_drop_down .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

/* new model*/
.main_content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
}
.main {
  position: absolute;
  top: 64px;
  right: 0;
}

.main_content:after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.abc {
  height: 100%;
  display: flex;
  align-items: center;
}

.main_content .pqr {
  overflow-y: auto;
}

.main_content .modelContent {
  width: 100%;
  padding: 15px;
  margin: 30px auto;
  max-height: calc(100% - 110px);
  overflow-x: hidden;
  background: white;
  z-index: 9999999;
  max-width: 550px;
}

.MuiPickersPopper-root {
  z-index: 999999999 !important;
}

@media (max-width: 992px) {
  .main_content .modelContent {
    width: 60%;
  }
}

@media (max-width: 576px) {
  .main_content .modelContent {
    width: 80%;
  }
}

.new_model {
  margin-top: 5px;
  padding: 0px;
}

.statusContainer {
  width: 100%;
}

.filter_modal .ModelFontFamily {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.filter_modal .modelHeader {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 18px;
  letter-spacing: 0.02857em;
}
