/*signup container css*/
.signup-container .css-15v22id-MuiAccordionDetails-root {
  padding: 0px 10px 2px;
}
.signup-container .css-o4b71y-MuiAccordionSummary-content {
  margin: 6px 0;
}
.signup-container .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.signup-container
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded,
.signup-container .MuiButtonBase-root {
  min-height: 34px;
  padding: 0 7px;
}

.signup-container .css-bqk0ke-MuiInputBase-root-MuiInput-root:before {
  border-color: #2a0651 !important;
}

/*finalizeSignup-page-css*/
.signup-container .MuiFormControl-root {
  margin-top: 0;
}

.signup-container .email-text {
  font-weight: bold;
  color: black;
}
