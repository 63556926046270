.didimo-detail-container > div {
    padding: 0 !important;
}

.viewer-container {
    position: relative;
}

.viewer-container .camera-face, .viewer-container .camera-body {
    position: absolute;
    z-index: 1000;
}

.viewer-container .camera-face {
    top: 40px;
    right: 40px;
}

.viewer-container .camera-body {
    top: 108px;
    right: 40px;
}

.viewer-container .camera-face svg, .viewer-container .camera-body svg {
    width: 48px;
    height: 48px;
    padding: 8px;
    background-color: var(--main);
    color: white;
    cursor: pointer;
}