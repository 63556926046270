:root {
  --main: #2a0651;
  --main-op75: rgba(42, 6, 81, 0.75);
  --main-op50: rgba(42, 6, 81, 0.5);
  --main-op25: rgba(42, 6, 81, 0.25);
  --main-op10: rgba(42, 6, 81, 0.1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.cardContentBox > div {
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
}

.cardContentBox .MuiTableHead-root {
  display: table-header-group;
  background-color: #fafafa;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiCardHeader-title.MuiTypography-h5 {
  font-size: 20px;
}

.MuiPaper-root.MuiDialog-paper {
  padding: 16px;
}

.MuiPaper-root.MuiDialog-paper > .MuiDialogTitle-root > .MuiTypography-root {
  font-size: 16px;
}
