.configure-template-container .inputCheck .MuiFormGroup-root {
  flex-direction: row;
}

.configure-template-container .items .MuiFormControlLabel-label {
  color: black;
}

.configure-template-container .animation .MuiFormGroup-root {
  flex-direction: column;
}
.configure-template-container .animation .MuiCheckbox-root {
  padding: 4px 4px 4px 10px;
}

.configure-template-container .model_main {
  border: solid 1px #00008b;
  border-radius: 5px;
  padding: 20px 20px 0;
  margin-top: 10px;
}
.configure-template-container
  .css-1vpywmi-MuiTypography-root-MuiDialogTitle-root {
  padding: 0 0 15px !important;
}
.configure-template-container
  .css-1vpywmi-MuiTypography-root-MuiDialogTitle-root
  h3 {
  font-size: 16px;
  color: #263238;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.configure-template-container .btnTemplate {
  float: right;
}

.configure-template-container .btnTemplate .MuiButton-root {
  text-transform: capitalize;
  padding: 5px 15px 0 0;
}

.configure-template-container .items {
  margin-bottom: 21px;
}

.configure-template-container .removeIcon {
  padding-right: 10px;
  cursor: pointer;
}

/*.configure-template-container .css-ypiqx9-MuiDialogContent-root{*/
/*    padding: 0;*/
/*}*/

 {
  margin-bottom: 21px;
}
.configure-template-container
  .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  margin: 10px 0;
  padding: 0;
}
.configure-template-container .css-1em9ut6-MuiGrid-root {
  padding: 0 !important;
}
.configure-template-container .group-title {
  display: flex;
  align-items: center;
}
.configure-template-container .group-title span {
  display: flex;
  vertical-align: middle;
  cursor: pointer;
}
.configure-template-container .group-title span svg {
  font-size: 16px;
  margin-top: 1px;
}
.configure-template-container .MuiFormControlLabel-label {
  font-size: 13px;
}
.configure-template-container .css-a5rdam-MuiGrid-root {
  width: 100%;
  margin: 0;
}
.configure-template-container ::-webkit-scrollbar {
  width: 5px;
}

.configure-template-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.configure-template-container ::-webkit-scrollbar-thumb {
  background: #888;
}
.configure-template-container .css-ypiqx9-MuiDialogContent-root {
  overflow-y: unset;
}
.configure-template-container .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow-y: auto;
  width: 550px;
}
.configure-template-container .css-q8hpuo-MuiFormControl-root {
  height: 47px;
  margin-top: 10px;
}

.remove_select {
  padding-right: 8px;
  cursor: pointer;
}

.textField_right {
  text-align: right;
}

.textField_center {
  text-align: center;
}

.table-head {
  font-size: 14px;
}
