.ddmCreate-Modal {
  border: 1px solid #462390;
  min-height: 300px;
  background-color: rgba(42, 6, 81, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ddmCreate-Modal:first-child {
  margin-bottom: 20px;
}

.ddmCreate-Modal img {
  width: 100%;
  height: auto;
}

.ddmCreate-Modal p {
  margin: 15px;
  text-align: center;
  opacity: 0.5;
  font-style: italic;
}

.ddmCreate-Modal.has-files p {
  display: none;
}

.points-warning {
  width: 270px;
}

.drag-over {
  border: 1px solid var(--main);
  background-color: var(--main-op25);
}

.MuiAlert-standardInfo {
  width: auto;
  margin-top: 58px;
}

/*
.new-ddm-model .MuiDialogContent-root {
  border: 1px solid #00008b;
  padding: 15px 25px 0 25px;
  border-radius: 5px;
}
*/

.new-ddm-model .MuiGrid-spacing-xs-2 {
  max-width: calc(100% + 16px) !important;
}

.custom-font-family {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.custom-font-family1 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.didimoTotalCost {
  width: auto;
  margin-top: 58px;
  padding: 6px 16px;
  border: 2px solid #b5b5dd;
  border-radius: 5px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
@media (max-width: 900px) {
  .didimoTotalCost {
    margin-top: 30px !important;
  }
}

.didimoTotalCost h4 {
  margin: 10px 0 11px;
  font-size: 22px;
}
.footer {
  padding: 2px 15px 0;
}
.footer h4 {
  font-size: 14px;
}
.btn-group .MuiButton-root {
  text-transform: capitalize;
}
.didimoTotalCost p {
  font-size: 14px;
}
.element-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.element-detail-container .upgradeBtn {
  font-size: 9px;
  background-color: #63a725;
  padding: 3px;
  border-radius: 5px;
  color: white;
  border: 1px solid grey;
}

.btn-group .css-j2p3un-MuiCircularProgress-root {
  width: 23px !important;
  height: 23px !important;
}
.loader {
  width: 551px;
  max-width: 551px;
  height: 580px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-version-spacing {
  margin-bottom: 20px;
}

.custom-border-model .select-version-spacing .MuiInputLabel-formControl {
  padding: 0 10px 0 5px;
  background: white;
  top: 2px;
}

.group-title {
  display: flex;
  align-items: center;
}
.group-title .learn-more-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.input-detail .didimo-code .MuiFormGroup-root {
  width: 100%;
}
.didimo-generate-select {
  width: 80%;
  margin-top: 10px;
}
.didimo-generate-select .css-t2jfvs-MuiInputBase-root-MuiOutlinedInput-root {
  height: 42px;
}

.didimo-generate-select .css-11a6dun-MuiFormLabel-root-MuiInputLabel-root {
  top: -2px !important;
}
.didimo-generate-select .css-t2jfvs-MuiInputBase-root-MuiOutlinedInput-root {
  top: 1px !important;
}

.ddm-generate-modal-container .footer {
  padding-top: 5px;
}
.ddm-generate-modal-container .footer .btn-group .MuiButton-root {
  padding: 0;
}
.ddm-generate-modal-container .generate-didimo-info-icon svg {
  font-size: 17px;
  margin-top: 1px;
}
.ddm-generate-modal-container .generate-didimo-info-icon {
  display: flex;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}
.ddm-generate-modal-container .select-remove-icon {
  margin-right: 5px;
  font-size: 18px;
  color: gray;
  cursor: pointer;
}
.generate-didimo-info-icon button {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.generate-didimo-info-icon button svg {
  fill: #000000;
}
.main_link {
  background-color: #4f1b75;
}
